<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">GDPR Cleanse Search</span>
          <b-button v-show="stage === 'search'" @click="goBack" class="btn-sm pull-right">Back</b-button>
          <b-button v-show="stage === 'found'" @click="goBackCleanseSearch" class="btn-sm pull-right">Back</b-button>
          <b-button v-show="stage === 'cleansed'" @click="goBackCleanseSearchFresh" class="btn-sm pull-right">Back to GDPR Search</b-button>
        </template>

          <div id="error" class="card border-danger" v-show="error">
            <div class="card-header bg-danger border-danger">

                <span class="text-white font-weight-bolder">
                <i class="fa fa-exclamation-triangle"></i>
                 &nbsp;
                {{ error_heading }}
                </span>

            </div>
            <div class="card-body border-danger text-dark font-weight-bold">

              {{ error_text }}

            </div>
          </div>

          <div v-show="stage === 'search'">

            <b-form-row class="mb-3">

              <b-col cols="6">
                <label class="">First Name</label>
                <b-input v-model="$v.details.first_name.$model" class="mb-2 mr-sm-2 mb-sm-0" :state="validateState('first_name')"></b-input>
                <b-form-invalid-feedback v-if="!$v.details.first_name.required">This is a required field.</b-form-invalid-feedback>
              </b-col>

              <b-col cols="6">
                <label class="">Last Name</label>
                <b-input v-model="$v.details.last_name.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('last_name')"></b-input>
                <b-form-invalid-feedback v-if="!$v.details.last_name.required">This is a required field.</b-form-invalid-feedback>
              </b-col>

            </b-form-row>

            <b-form-row class="mb-3">

              <b-col cols="6">
                <label class="">Date Of Birth</label>

                <b-input-group class="mb-3">
                  <b-form-input
                    id="example-input"
                    v-model="$v.details.date_of_birth.$model"
                    type="date"
                    locale="en-GB"
                    :state="validateState('date_of_birth')"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="$v.details.date_of_birth.$model"
                      button-only
                      right
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="en-GB"
                      :state="validateState('date_of_birth')"
                      aria-controls="example-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                  <b-form-invalid-feedback v-if="!$v.details.date_of_birth.isDate">Please enter a valid date.</b-form-invalid-feedback>
                </b-input-group>

              </b-col>

              <b-col cols="6">
                <label class="">Postcode</label>
                <b-input v-model="$v.details.postcode.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('postcode')"></b-input>
                <b-form-invalid-feedback v-if="!$v.details.postcode.required">This is a required field.</b-form-invalid-feedback>
              </b-col>

            </b-form-row>

          </div>

          <div v-show="stage === 'found'">

            <b-form-row class="mb-3">

              <b-col cols="6">

                <p>
                  <b>First name: </b>{{this.details.first_name}} <br>
                  <b>Last name: </b>{{this.details.last_name}} <br>
                  <b>Date Of Birth: </b>{{formatted_date_of_birth}} <br>
                  <b>Postcode: </b>{{this.details.postcode}}
                </p>

              </b-col>

              <b-col cols="6">

                <table class="table">

                  <tr>
                    <th colspan="2">Results found</th>
                  </tr>

                  <tr>
                    <th>
                      Quotes
                    </th>
                    <td>
                      {{this.found_data.quotes}}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Policies
                    </th>
                    <td>
                      {{this.found_data.policies}}
                    </td>
                  </tr>

                </table>

              </b-col>

            </b-form-row>

          </div>

          <div v-show="stage === 'cleansed'">

            <b-alert
              show
              dismissible
              variant="success"
            >
              <i class="fa fa-check mr-2"></i>
              All records of quotes/policies matching {{this.details.first_name}} {{this.details.last_name}} {{formatted_date_of_birth}} at {{this.details.postcode}} have been anonymised or purged from the system.
            </b-alert>

          </div>

          <template slot="footer">
            <b-button v-show="stage === 'search'" @click="goBack" class="float-left">Back</b-button>
            <b-button v-show="stage === 'found'" @click="goBackCleanseSearch" class="float-left">Back</b-button>
            <b-button v-show="stage === 'cleansed'" @click="goBack" class="float-left">Back</b-button>

            <b-button v-show="stage === 'search'" variant="success" @click="search" class="float-right">Search</b-button>
            <b-button v-show="stage === 'found'" variant="success" @click="cleanse" :disabled="found_data.policies === 0 && found_data.quotes === 0" class="float-right">Confirm & Cleanse</b-button>
            <b-button v-show="stage === 'cleansed'" variant="outline-dark" @click="goBackCleanseSearchFresh" class="float-right">New & Cleanse Search</b-button>
          </template>

      </b-card>

    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";
  import { validationMixin } from 'vuelidate'
  import { required } from "vuelidate/lib/validators";
  import moment from 'moment'

  const isDate = (value) => {
    return moment(value, 'YYYY-MM-DD', true).isValid()
  };

  export default {
    name: 'GDPRCleanse',
    components: {Id},
    mixin: [validationMixin],
    props: {
      caption: {
        type: String,
        default: 'GDPR Cleanse '
      },
    },
    data: () => {
      return {
        error: false,
        error_heading: '',
        error_text: '',
        stage: 'search',
        details: {
          first_name: null,
          last_name: null,
          date_of_birth: null,
          postcode: null,
        },
        found_data: {
          quotes: null,
          quotes_ids: null,
          policies: null,
          policies_ids: null
        }
      }
    },
    validations() {

      return {
        details: {
          first_name: {
            required
          },
          last_name: {
            required
          },
          date_of_birth: {
            required,
            isDate (value) {
              return isDate(value)
            }
          },
          postcode: {
            required
          }
        }
      }

    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      goBackCleanseSearch() {
        this.stage = 'search'
      },
      goBackCleanseSearchFresh() {

        this.details = {
          first_name: null,
          last_name: null,
          date_of_birth: null,
          postcode: null,
        }
        this.found_data = {
          quotes: null,
          quotes_ids: null,
          policies: null,
          policies_ids: null
        }
        this.$v.$reset();
        this.$v.details.$reset();
        this.stage = 'search'
      },
      validateState(name) {

        if (this.$v.details[name]) {
          const {$dirty, $error} = this.$v.details[name];
          return $dirty ? !$error : null;
        }
      },
      search() {

        this.error = false;

        this.$v.$touch();

        if(this.$v.$anyError) {

          window.scrollTo(document.querySelector('#error').offsetTop, 100);
          this.error = true;
          this.error_heading = 'There are errors on the form';
          this.error_text = 'Please fix the errors and re-submit the form';
          return

        } else {

          let url = '/gdpr-search/?product=' + localStorage.getItem('selected_product');

          const post_data = JSON.parse(JSON.stringify(this.details));

          axios.post(url, post_data).then(
            response => {
              this.details = response.data.details
              this.found_data.quotes = response.data.quotes
              this.found_data.quotes_ids = response.data.quotes_ids
              this.found_data.policies = response.data.policies
              this.found_data.policies_ids = response.data.policies_ids
              this.stage = 'found'
            }
          ).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            if (error.response.status === 400) {

              window.scrollTo(document.querySelector('#error').offsetTop, 100);
              this.error = true;
              this.error_heading = 'There are errors on the form';
              this.error_text = 'Please fix the errors and re-submit the form';

              if (error.response.data) {

                //this.server_errors = error.response.data

                for (var key in error.response.data){
                  this.server_errors[key] = error.response.data[key][0]
                }
                this.$v.$reset();
                this.$v.$touch();

              }

            }

            console.log('premium error');
            console.log(error.response)
          });

        }
      },
      cleanse() {

        this.error = false;

        this.$v.$touch();

        if(this.$v.$anyError) {

          window.scrollTo(document.querySelector('#error').offsetTop, 100);
          this.error = true;
          this.error_heading = 'There are errors on the form';
          this.error_text = 'Please fix the errors and re-submit the form';
          return

        } else {

          const post_data = {
            quotes: this.found_data.quotes_ids,
            policies: this.found_data.policies_ids
          };

          let url = '/gdpr-cleanse/?product=' + localStorage.getItem('selected_product');

          axios.post(url, post_data).then(
            response => {

                console.log(response)
                this.stage = 'cleansed'
                if (response.status_code === 200) {
                  this.stage = 'cleansed'
                }

            }
          ).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            if (error.response.status === 400) {

              window.scrollTo(document.querySelector('#error').offsetTop, 100);
              this.error = true;
              this.error_heading = 'There are errors on the form';
              this.error_text = 'Please fix the errors and re-submit the form';

              if (error.response.data) {

                //this.server_errors = error.response.data

                for (var key in error.response.data){
                  this.server_errors[key] = error.response.data[key][0]
                }
                this.$v.$reset();
                this.$v.$touch();

              }

            }

            console.log('premium error');
            console.log(error.response)
          });

        }
      }
    },
    computed: {
      hasAccess() {

        let access = ['full_admin', 'customer_services'];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
      formatted_date_of_birth: function () { return moment(String(this.details.date_of_birth)).format('DD/MM/YYYY')},
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
